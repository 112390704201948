/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://unsplash.com/photos/Vp3oWLsPOss"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://circleci.com/blog/january-4-2023-security-alert/"
  }, "CircleCI security alert: Rotate any secrets stored in CircleCI")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://circleci.com/blog/jan-4-2023-incident-report/"
  }, "CircleCI incident report for January 4, 2023 security incident")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/features/actions"
  }, "GitHub Actions")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.travis-ci.com/"
  }, "Travis CI")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://palant.info/2023/01/02/south-koreas-online-security-dead-end/"
  }, "South Korea’s online security dead end")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://palant.info/2023/01/09/touchen-nxkey-the-keylogging-anti-keylogger-solution/"
  }, "TouchEn nxKey: The keylogging anti-keylogger solution")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "위 보안글 한국어 번역: ", React.createElement(_components.a, {
    href: "https://github.com/alanleedev/KoreaSecurityApps/tree/main"
  }, "KoreaSecurityApps")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://opennet.or.kr/wp-content/uploads/2013/05/e-finance-reform-details.pdf"
  }, "오픈넷 - 공인인증서의 문제점")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.mois.go.kr/frt/sub/a06/b04/easyCertification/screen.do"
  }, "간편인증")), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
